<template>
  <div class="service-item-container h-100">
    <div class="service-item h-100">
      <div class="service-title-bar">
        <p class="service-icon">
          <img :src="serviceItem.fullImage" :alt="serviceItem.title" />
          <img :src="serviceItem.custom_link" :alt="serviceItem.title" class="service-hover-img" />
        </p>
        <h3 v-html="serviceItem.title" class="service-heading"></h3>
      </div>
      <p>{{serviceItem.content}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Service",
  props: ["serviceItem"]
};
</script>