<template>
  <section class="services">
    <div class="services-container">
      <div class="container">
        <h3 class="section-heading text-center">Video Services We Offer</h3>
        <div class="row">
          <div
            class="col-xl-4 col-md-6 mb-lg-4"
            v-bind:key="serviceItem.id"
            v-for="serviceItem in SingleServices"
          >
            <Service v-bind:serviceItem="serviceItem" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Service from "@/components/Common/Service.vue";

export default {
  name: "SingleServices",
  components: {
    Service
  },
  props: ["SingleServices"]
};
</script>