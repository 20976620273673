<template>
  <div class="services-page">
    <FixedNavbar />
    <div class="su_page_content">
      <TitleBanner v-bind:PageTitle="BannerTitle" />
      <SingleServices v-bind:SingleServices="services" />
      <Resources />
      <QuoteCta v-if="widgetCustomHTML.length>0" v-bind:CustomWidget="widgetCustomHTML" />
    </div>
    <Footer />
  </div>
</template>
<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import TitleBanner from "@/components/Common/TitleBanner.vue";
import SingleServices from "@/components/ServicesPage/SingleServices.vue";
import Resources from "@/components/Common/Resources/Resources.vue";
import QuoteCta from "@/components/Common/QuoteCta.vue";
import Footer from "@/components/Common/Footer.vue";
import axios from "axios";

export default {
  name: "Services",
  components: {
    FixedNavbar,
    TitleBanner,
    SingleServices,
    Resources,
    QuoteCta,
    Footer
  },
  data() {
    return {
      services: [],
      widgetCustomHTML: [],
      BannerTitle: []
    };
  },
  mounted() {
    this.getBanner();
    this.getServices();
    this.getQuote();
    this.setMetaInfo(402)
  },
  methods: {
    getBanner() {
      this.loader1 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "service-page-banner"
            // custom_link_field: "cta_button"
          }
        })
        .then(response => {
          this.loader1.hide();
          // this.BannerContent = response.data.customPostList;
          this.BannerTitle = response.data.customPostList;
          console.log("BannerContent==", this.BannerContent);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getServices() {
      this.loader2 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "our_services",
            custom_link_field: "service_hover_icon"
          }
        })
        .then(response => {
          this.loader2.hide();
          this.services = response.data.customPostList;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getQuote() {
      this.loader = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
          params: {
            type: "widget_custom_html"
          }
        })
        .then(response => {
          this.loader.hide();
          this.widgetCustomHTML = response.data.customWidgetList;
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>




